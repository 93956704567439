import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {faGithub, faLinkedin} from "@fortawesome/free-brands-svg-icons";

import {createApp} from 'vue';
import App from './App.vue';
import router from "@/router";

library.add(fas, faGithub, faLinkedin);

const app = createApp(App);
app.component('fa-icon', FontAwesomeIcon);
app.use(router);
app.mount('#app');
