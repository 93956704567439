<template>
  <div class="container">
    <Jumbotron/>
    <Cards/>
    <Partner/>
  </div>
</template>

<script>
import Jumbotron from "@/components/home/Jumbotron.vue";
import Cards from "@/components/home/Cards.vue";
import Partner from "@/components/home/Partner.vue";

export default {
  name: 'HomeComponent',
  components: {Jumbotron, Cards, Partner},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
