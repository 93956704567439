<template>
  <section class="logo-list">
    <div class="container my-3">
      <div class="row">
        <div class="col-lg-2 col-md-4 col-6 align-content-center">
          <a href="https://www.tmdt.uni-wuppertal.de/"><img src="../../assets/partner/tmdt.webp" class="img-fluid"></a>
        </div>
        <div class="col-lg-2 col-md-4 col-6 align-content-center">
          <a href="https://www.uni-wuppertal.de"><img src="../../assets/partner/buw.png" class="img-fluid"></a>
        </div>
        <div class="col-lg-2 col-md-4 col-6 align-content-center">
          <a href="https://www.izmd.uni-wuppertal.de/"><img src="../../assets/partner/izmd.webp" class="img-fluid"></a>
        </div>
        <div class="col-lg-2 col-md-4 col-6 align-content-center">
          <a href="https://www.bit-ki.de/"><img src="../../assets/partner/galabau.jpg" class="img-fluid"></a>
        </div>
        <div class="col-lg-2 col-md-4 col-6 align-content-center">
          <a href="https://www.handwerk-rhein-erft.de/"><img src="../../assets/partner/khre.png" class="img-fluid"></a>
        </div>
        <div class="col-lg-2 col-md-4 col-6 align-content-center">
          <a href="https://handwerkdigital.de/"><img src="../../assets/partner/mhd.png" class="img-fluid"></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'PartnerComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo-list {
  padding: 20px 0;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
.logo-list img {
  max-height: 100px; /* Maximale Höhe für alle Logos */
  width: auto; /* Breite proportional anpassen */
  object-fit: contain; /* Seitenverhältnis beibehalten */
}
.logo-list img {
  display: inline-block;
  max-width: 85%;
  padding: 15px 0;
  transition: all 0.3s ease-in-out;
  -webkit-filter: grayscale(100);
  -moz-filter: grayscale(100);
  filter: grayscale(100);
}
.logo-list img:hover {
  filter: none;
  transform: scale(1.2);
  -webkit-filter: none;
  -moz-filter: none;
}
</style>
