<template>
  <div class="container">
    <h1>Über uns</h1>
  </div>
</template>

<script>

export default {
  name: 'AboutUsComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
