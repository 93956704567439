import {createRouter, createWebHistory} from "vue-router";

import Home from '@/views/Home.vue'
import Impressum from '@/views/Impressum.vue'
import Datenschutz from '@/views/Datenschutz.vue'
import IoT from '@/views/IoT.vue'
import LLM from '@/views/LLM.vue'
import Datenmanagement from '@/views/DatenManagement.vue'
import Seminare from '@/views/Seminare.vue'
import Hackathons from '@/views/Hackathons.vue'
import AboutUs from '@/views/AboutUs.vue'
import FuE from '@/views/FuE.vue'
import Team from '@/views/Team.vue'

const router = createRouter({
        history: createWebHistory(),
        routes: [
            {
                path: '/',
                name: 'home',
                component: Home
            },
            {
                path: '/impressum',
                name: 'impressum',
                component: Impressum
            },
            {
                path: '/datenschutz',
                name: 'datenschutz',
                component: Datenschutz
            },
            {
                path: '/iot',
                name: 'iot',
                component: IoT
            },
            {
                path: '/llm',
                name: 'llm',
                component: LLM
            },
            {
                path: '/datenmanagement',
                name: 'datenmanagement',
                component: Datenmanagement
            },
            {
                path: '/seminare',
                name: 'seminare',
                component: Seminare
            },
            {
                path: '/hackathons',
                name: 'hackathons',
                component: Hackathons
            },
            {
                path: '/aboutus',
                name: 'aboutus',
                component: AboutUs
            },
            {
                path: '/fue',
                name: 'fue',
                component: FuE
            },
            {
                path: '/team',
                name: 'team',
                component: Team
            }
        ]
    }
)

export default router;