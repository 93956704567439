<template>
  <div class="container my-5">
    <div class="row">
      <div class="col-md-5">
        <h1 class="font-weight-light">Ihre Daten sind unser Handwerk</h1>
        <p class="mt-4">
          In der heutigen datengetriebenen Welt sind Informationen der Schlüssel zum Erfolg. Aber was nützen Daten, wenn sie nicht zugänglich und nutzbar sind? Wir bieten maßgeschneiderte Lösungen für Datenzugänglichkeit, um sicherzustellen, dass Sie Ihre Daten optimal nutzen können. Vertrauen Sie auf unsere Expertise, um fundierte Entscheidungen zu treffen und Innovationen voranzutreiben. Kontaktieren Sie uns noch heute und nutzen Sie Ihr Datenpotenzial!
        </p>
      </div>
      <div class="col-md-7"><img src="../../assets/home/data2.jpg" class="img-fluid"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JumbotronComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
