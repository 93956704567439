<template>
  <header>
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-dark sticky-top" style=" padding: 0">
      <img src="../assets/Logo_2024.png" width="200">
      <div class="navbar-expand-lg navbar-dark flex flex-grow-1" style="margin-right: 10px;">
        <div class="align-content-center" style="background-color: #2B2D42; height: 65px; margin-top: 5px">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link class="nav-link" aria-current="page" :to="{ name: 'home' }">
                  <fa-icon class="me-2" :icon="['fas', 'home']" size="xs"/>
                  Home
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <!-- Navbar -->
  </header>
</template>

<script>
export default {
  name: 'NavBarComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* ************ Activate Hover ************ */
@media screen and (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }

  .dropdown .dropdown-menu {
    display: none;
  }

  .dropdown:hover .dropdown-toggle::after {
    border-top: 0;
    border-bottom: .3em solid;
  }
}

@media screen and (max-width: 991px) {
  .dropdown-toggle.show::after {
    border-top: 0;
    border-bottom: .3em solid;
  }
}

/* ************ Activate Color Change on Hover ************ */
.navbar .navbar-nav .nav-link:hover {
  color: #8d99ae;
}

/* ************ Styling ************ */
.navbar-brand {
  color: white;
  font-size: 1.5em
}

.navbar .navbar-nav .nav-link {
  transition: all .5s;
  color: white;
  font-size: 1.1em
}

.navbar .nav-item .dropdown-menu {
  color: white;
  background-color: #2B2D42;
}

.navbar .nav-item .dropdown-menu .dropdown-item:hover {
  color: white;
  background-color: #8d99ae;
}

</style>
