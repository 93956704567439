<template>
  <div class="container">
    <h1>Hackathons</h1>
  </div>
</template>

<script>

export default {
  name: 'HackathonsComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
