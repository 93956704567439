<template>
  <div class="container">
    <div class="row col-md-12">
      <h2>Wir helfen Ihnen, wertvolle Daten aus ihrem täglichen Betrieb zu gewinnen und diese für strategische Entscheidungen zu nutzen.</h2>
      <div class="col-md-4">
        <div class="card">
          <img src="../../assets/home/data.jpg" class="card-img-top img-fluid">
          <div class="card-body">
            <h5 class="card-title">Datenmanagement</h5>
            <p class="card-text">Mit unseren Datenmanagementlösungen helfen wir Handwerksbetrieben dabei den Überblick über Ihre Daten im Unternehmen zu behalten.</p>
<!--            <router-link :to="{ name: 'iot' }" class="btn btn-primary">Mehr</router-link>-->
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <img src="../../assets/home/iot.jpg" class="card-img-top img-fluid">
          <div class="card-body">
            <h5 class="card-title">Echtzeit-Überwachung</h5>
            <p class="card-text">Wir integrieren intelligente, IoT-basierte Geräte in handwerkliche Prozesse, um Echtzeitdaten zu erfassen, Ressourcen zu optimieren und präzise Steuerungen zu ermöglichen.</p>
<!--            <router-link :to="{ name: 'llm' }" class="btn btn-primary">Mehr</router-link>-->
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <img src="../../assets/home/chatgpt.jpg" class="card-img-top img-fluid">
          <div class="card-body">
            <h5 class="card-title">Automatisierung von Routineaufgaben</h5>
            <p class="card-text">Mit fortschrittlichen KI-Technologien wie ChatGPT helfen wir Unternehmen, Routineaufgaben zu automatisieren, eine effiziente Kundenkommunikation zu ermöglichen und Fachkräfte mit intelligenten Assistenzsystemen zu unterstützen.
            </p>
<!--            <router-link :to="{ name: 'datenmanagement' }" class="btn btn-primary">Mehr</router-link>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardsComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top {
  width: 100%;
  height: 20vh;
  object-fit: cover;
}
</style>
