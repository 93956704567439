<template>
  <footer class="text-center text-lg-start text-white" style="background-color: #2B2D42">

    <!-- Section: Links  -->
    <section class="p-4">
      <div class="container text-center text-md-start mt-5">
        <!-- Grid row -->
        <div class="row mt-3">
          <!-- Grid column -->
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <!-- Content -->
            <h6 class="text-uppercase fw-bold">Crafting Systems GmbH</h6>
            <hr
                class="mb-4 mt-0 d-inline-block mx-auto"
                style="width: 60px; background-color: #2B2D42; height: 2px"
            />
            <p>
              "The way to get started is to quit talking and begin doing." (Walt Disney)
            </p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold">Rechtliches</h6>
            <hr
                class="mb-4 mt-0 d-inline-block mx-auto"
                style="width: 60px; background-color: #7c4dff; height: 2px"
            />
            <p>
              <fa-icon class="me-1" :icon="['fas', 'scale-balanced']" color="white"/>
              <router-link :to="{ name: 'impressum' }" class="text-white">Impressum</router-link>
            </p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold">Follow us</h6>
            <hr
                class="mb-4 mt-0 d-inline-block mx-auto"
                style="width: 60px; background-color: #7c4dff; height: 2px"
            />
            <p>
              <fa-icon class="me-1" :icon="['fab', 'linkedin']" color="white"/>
              <a href="https://www.linkedin.com/company/crafting-systems/" class="text-white">LinkedIn</a>
            </p>
            <p>
              <fa-icon class="me-1" :icon="['fab', 'github']" color="white"/>
              <a href="https://github.com/Crafting-Systems" class="text-white">GitHub</a>
            </p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold">Kontakt</h6>
            <hr
                class="mb-4 mt-0 d-inline-block mx-auto"
                style="width: 60px; background-color: #7c4dff; height: 2px"
            />
            <p>
              <fa-icon class="me-1" :icon="['fas', 'home']" color="white"/>
              Neustr. 64, 41836 Hückelhoven
            </p>
            <p>
              <fa-icon class="me-1" :icon="['fas', 'envelope']" color="white"/>
              <a href="mailto:info@crafting-systems.de" class="text-white">info@crafting-systems.de</a>
            </p>
            <p>
              <fa-icon class="me-1" :icon="['fas', 'phone']" color="white"/>
              <a href="tel:+49 151 629 878 81" class="text-white">+49 151 629 878 81</a>
            </p>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!-- Section: Links  -->

    <div class="text-center p-3" style="background-color: #2B2D42">© 2023 Copyright:
      <a class="text-white" href="https://www.crafting-systems.de">Crafting Systems GmbH</a>
    </div>

  </footer>
</template>

<script>


export default {
  name: 'FooterComponent'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
